import React from 'react';
import './aboutus.css';

const AboutUs = () => {
  return (
    <div id="about-us" className="about-us-container">
      <h1 className="about-us-title">About Us</h1>
      <div className="about-us-section">
        <div className="about-us-description">
          <p>
          Welcome to MC Digital Strategy – Development Branch!
          </p>
          <hr className='header-line-under' style={{backgroundColor: 'grey'}}/>
          <p>
          Founded by Marcus and Nii Mahliaire, MC Digital Strategy is focused on driving business success through innovative digital solutions. This site serves as Marcus Mahliaire’s developer portfolio, showcasing his expertise in custom web development.
          </p>
          <hr className='header-line-under' style={{backgroundColor: 'grey'}}/>
          <p>
          Marcus leads MC Digital Development, where we specialize in building responsive, user-friendly websites and web applications. With a deep understanding of modern web technologies, we deliver tailored solutions designed to enhance your online presence and meet your unique goals.
          </p>
          
          <button className="connect-button" onClick={() => document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' })}>
            Connect
          </button>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
