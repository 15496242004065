import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './card.css';
import { Button } from '@mui/material';

const Card = ({ title, backContentTitle, backContentDesc, backContentPic, backContentDescription, projectUrl, onClick, projectDesc, frontBackgroundImage }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleCardClick = () => {
        setIsFlipped(!isFlipped);
    };

    const handleLinkClick = (e) => {
        e.stopPropagation(); // Prevents the card flip event from firing
        onClick(); // Calls the function passed from the parent component
    };

    return (
        <motion.div 
            className='card'
            style={{ 
                cursor: 'pointer',
                perspective: '1500px',
                margin: '20px',
                position: 'relative',
                width: '650px',
                height: '350px',
                borderRadius: '10px',
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transformStyle: 'preserve-3d',
                boxShadow: "0px 10px 20px rgba(0,0,0,.8)",
                background: 'linear-gradient(to bottom, #f0f0f0 0%, #a0a0a0 100%)',
            }}
            onClick={handleCardClick}
            animate={{ rotateY: isFlipped ? 180 : 0 }}
            whileHover={{ scale: 1.06, rotate: 4, boxShadow: "0px 10px 20px rgba(0,0,0,.9)" }}
            transition={{ type: 'spring', stiffness: 300, damping: 20 }}
        >
            {/* Front Side */}
            <div style={{ 
                position: 'absolute',
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                backfaceVisibility: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: isFlipped ? -1 : 1,
                backgroundImage: `url(${frontBackgroundImage})`, 
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                padding: 0,
            }}>
                <h3>{title}</h3>
            </div>

            {/* Back Side */}
            <div className="card-back" style={{ 
                position: 'absolute',
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                backfaceVisibility: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                transform: 'rotateY(180deg)',
                zIndex: isFlipped ? 1 : -1,
                backgroundImage: `url(${backContentPic})`, 
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <h2 style={{color: '#111', }}>{backContentTitle}</h2>
                <h4 className='back-content-description' style={{color: '#111',}}>{backContentDescription}</h4>
                <Button
                  variant="contained"
                  onClick={handleLinkClick}
                  style={{ 
                    width: 'calc(100% - 110px)',
                    marginTop: 'auto',
                    padding: 7,
                    bottom: 20,
                  }}
                  href={projectUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {backContentDesc}
                </Button>
            </div>
        </motion.div>
    );
};

export default Card;
