import React from 'react';
import './hero.css';
// import HeroPopUp from './HeroPopUp';
import Welcome from './Welcome';
// import { AnimatePresence } from 'framer-motion';
// import cute from '../../assets/illistrated-portrait-bg.png'

const Hero = () => {

    return (
        <div className="hero-section">
            <Welcome/>
            {/* <AnimatePresence>
            <HeroPopUp header={'Front-End Engineer'} description={'Specializing in React, JavaScript, HTML, and CSS, I am committed to creating unique digital experiences. My work is driven by a passion for creativity, giving each website its own distinctive character.'} buttonOneLabel={'View Projects'} hiddenImage={cute} title={'Welcome!'} />
            </AnimatePresence> */}
        </div>
    );
};

export default Hero;
