import React from 'react'
import './testimonials.css'

const Testimonials = () => {
  const testimonialsData = [
    {
      author: "Michael Brown, Managing Director of Tech Innovations",
      content: "MC Digital Development revamped our company website, making it not only aesthetically pleasing but also highly functional. Their expertise in SEO and user experience design has greatly enhanced our online presence."
    },
    {
      author: "Sarah Green, Co-Founder of Eco-Friendly Solutions",
      content: "Working with Marcus was a game-changer for our startup. The web solution provided by MC Digital Development helped us automate key processes, saving us time and money. We couldn't be happier with the results."
    },
    {
      author: "David Wilson, Owner of Gourmet Delights",
      content: "The team's professionalism and attention to detail are second to none. Our new website has received numerous compliments, and the seamless integration of e-commerce features has boosted our sales tremendously."
    }
  ];
  
  return (
    <div id="testimonials" className='testimonials-container'>
      <h1 className='testimonials-title'>Testimonials</h1>
      <hr className='header-line'/>
      <p className='testimonials-paragraph'>Hear from our satisfied clients who have experienced our exceptional service and results.</p>
      <hr style={{marginBottom: '50px'}} className='header-line-under'/>
      {testimonialsData.map((testimonial, index) => (
        <div key={index} className='testimonial'>
          <p className='testimonial-content'>"{testimonial.content}"</p>
          <p className='testimonial-author'>- {testimonial.author}</p>
        </div>
      ))}
    </div>
  )
}

export default Testimonials;
