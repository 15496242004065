import { Button } from '@mui/material';
import './projectdetails.css';

const ProjectDetails = ({ projectData, onClose }) => {
  const builtWithPrefix = "Built With:";
  
  return (
    <div className="project-details-container" style={{
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'space-between',
      alignItems: 'center', 
      background: 'linear-gradient(to top, #002c4b 0%, #00223b 50%)',
      borderRadius: '10px', 
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      position: 'absolute',
      top: '50%',
      left: '50%', 
      transform: 'translate(-50%, -50%)',
      width: '100%',
      maxWidth: '1200px',
      height: '100%',
      zIndex: 1000,
      overflow: 'hidden',
      margin: 0,
      padding: 1,
      border: 'none',
    }}>
      <div>
        <h1 className='project-details-title'>{projectData.title}</h1>
      </div>
      <div>
        <img className='project-details-image' src={projectData.imageSrc} alt={projectData.title} style={{
          maxWidth: '100%',
          height: 'auto',
          borderRadius: '5px',
          marginLeft: '3%',
        }} />
      </div>
      <div>
        <h3 className='project-details-description'>{projectData.description}</h3>
      </div>
      <div>
        <p className='project-details-built'><strong>{builtWithPrefix}</strong> {projectData.builtWith}</p>
      </div>
      <div className='project-details-button'>
        <Button variant="contained" color='primary' onClick={() => window.open(projectData.projectUrl, "_blank")}>See More</Button>
        <Button variant="contained" color='secondary' onClick={onClose}>Close</Button>
      </div>
    </div>
  );
};

export default ProjectDetails;
