import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
import './popup2.css';

const MUIFramerMotionPopup = ({ title, header, description, open, onClose, buttonOneLabel, buttonTwoLabel }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={motion.div}
            PaperProps={{
                initial: { scale: 0 },
                animate: { scale: 1 },
                exit: { scale: 0 },
                transition: { duration: 0.5 }
            }}
            sx={{
                '& .MuiDialog-paper': {
                    backgroundColor: '#f5f5f5', // Light grey background
                    borderRadius: 2,
                    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
                    maxWidth: '600px', // Increased width
                    width: '80%', // Ensure it scales with the screen size
                    padding: '20px' // Added padding for better spacing
                }
            }}
        >
            <DialogTitle sx={{ textAlign: 'center', color: '#333', fontWeight: 'bold', fontSize: '1.8em' }}>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText variant="h6" sx={{ textAlign: 'center', color: '#555', marginBottom: 2 }}>
                    {header}
                </DialogContentText>
                <DialogContentText sx={{ textAlign: 'left', paddingLeft: '5%', paddingRight: '5%', color: '#666' }}>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', padding: '16px' }}>
                {buttonOneLabel && (
                    <Link to="services" smooth={true} duration={500}>
                    <Button variant="contained" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
                        {buttonOneLabel}
                    </Button>
                    </Link>
                )}
                {buttonTwoLabel && (
                    <Link to="testimonials" smooth={true} duration={500}>
                        <Button variant="contained" color="secondary" onClick={onClose}>
                            {buttonTwoLabel}
                        </Button>
                    </Link>
                )}
            </DialogActions>
        </Dialog>
    );
};

MUIFramerMotionPopup.defaultProps = {
    title: 'Your App Title',
    header: 'Front-End Engineer',
    description: 'Your introduction or tagline goes here, explaining who you are and what you do.',
    buttonOneLabel: 'View Portfolio',
    buttonTwoLabel: 'Contact Me',
    open: false,
    onClose: () => {},
};

export default MUIFramerMotionPopup;
