import React from 'react';
import './ContactForm.css'

const ContactForm = () => {
  return (
    <div id="contact-us" className="contact-form-container">
      <h1 className="contact-form-header">Contact Us</h1>
      <hr className='header-line-under'/>
      <form action="https://formspree.io/f/mqazeqwd" method="POST">
        <p>
          <label>Name: <input type="text" name="name" required className="contact-input" /></label>
        </p>
        <p>
          <label>Email: <input type="email" name="email" required className="contact-input" /></label>
        </p>
        <p>
          <label>How Can We Help? <textarea name="message" required className="contact-textarea"></textarea></label>
        </p>
        <p>
          <button type="submit" className="contact-button">Send</button>
        </p>
      </form>
    </div>
  );
};

export default ContactForm;
