import './App.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
// import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Separator from './components/Separator/Separator';
import AboutUs from './components/AboutUs/AboutUs';
import Testimonials from './components/Testimonials/Testimonials';
import Services from './components/Services/Services'
import ContactForm from './components/ContactForm/ContactForm'

function App() {
  return (
    <div id="top" className="App">
      <Header/>
      <Hero/>
      <Footer/>
      <Separator/>
      {/* <About/> */}
      <AboutUs/>
      <Separator/>
      <Testimonials/>
      <Separator/>
      <Services/>
      <Separator/>
      <ContactForm/>
      <Separator/>
      <Contact/>
    </div>
  );
}

export default App;
