import React, { useState, useRef, useEffect } from 'react';
import './footer.css';
import Card from './Card';
import ProjectDetails from '../Footer/ProjectDetails';
import projectPhoto from '../../assets/pixelbridgelanding.png';
import seekPhoto from '../../assets/seek-desktop-screenshot.png'
import seekTransparent from '../../assets/final-card-background.png'
import pixelBridgeBack from '../../assets/pixelbridge-back.png'
import greeniq from '../../assets/green-iq-screenshot-black.png'
import greeniqBlurred from '../../assets/greeniq-blurred.png'
import seekDesktopPhoto from '../../assets/seek-desktop-screenshot.png'

const projectDetailsData = {
  'seek-app': {
    title: 'Seek by iNaturalist',
    description: 'A modern, engaging website designed for iNaturalists seek app, highlighting nature exploration and community involvement. The site features a sleek design with interactive elements, including real-time challenges, educational content, and community integration. Built with Webflow, the site ensures a seamless user experience across devices.',
    builtWith: 'Webflow, JavaScript, CSS.',
    imageSrc: seekPhoto,
    projectUrl: 'https://seek-inaturalist.webflow.io/',
  },
  'pixelbridge': {
    title: 'PixelBridge Website',
    description: 'A comprehensive website for PixelBridge, showcasing their digital cloud solutions and services. The site features a professional and sleek design, user-friendly navigation, and detailed information about the company’s offerings. Built using Wix to ensure a quick and efficient deployment.',
    builtWith: ' Wix',
    imageSrc: projectPhoto,
    projectUrl: 'https://mmahliaire.wixsite.com/pixelbridge',
  },
  'greeniq': {
    title: 'Green IQ Dispensary Website',
    description: 'A comprehensive website for Green IQ, showcasing their high-quality cannabis products and educational resources. The site features a professional and modern design, user-friendly navigation, and detailed information about the dispensary’s offerings. Built using Webflow to ensure a responsive and visually appealing experience.',
    builtWith: 'Webflow',
    imageSrc: greeniq,
    projectUrl: 'https://green-iq.webflow.io/',
  },

  // 'wine-recommendation': {
  //   title: 'Wine Enthusiast',
  //   description: 'A personalized wine recommendation app that allows users to explore wines by color. Each suggestion includes details on the wine’s name, region, and type. The app uses MongoDB for data storage and features engaging Lottie animations for a rich user experience.',
  //   builtWith: 'HTML, CSS, JavaScript, Node.js, Express.js, MongoDB, Heroku.',
  //   imageSrc: bg2,
  //   projectUrl: 'https://wine-recommendations-5b2c71ed2a9e.herokuapp.com/',
  // },
};

const Footer = () => {
  const [activeProject, setActiveProject] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const closeTimeoutRef = useRef(null);

  const showProjectDetails = (projectKey) => {
    if (closeTimeoutRef.current) {
      clearTimeout(closeTimeoutRef.current);
      closeTimeoutRef.current = null;
    }
    setActiveProject(projectDetailsData[projectKey]);
    setShowDetails(true);
  };

  const hideProjectDetails = () => {
    setShowDetails(false);
    closeTimeoutRef.current = setTimeout(() => {
      setActiveProject(null);
    }, 1500);
  };

  useEffect(() => {
    return () => {
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div id='projects' className='footer-container' style={{ position: 'relative', overflow: 'hidden' }}>
      <div className='fade-up'>
        <h1 className='project-title'>Projects</h1>
        <hr className="header-line" />
        <h4 className="centered-title">Review a selection of our latest, high-quality web projects below.</h4>
        <hr className='header-line-under'/>
      </div>
      <div className='card-container' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
        <div className="card-title">Seek by iNaturalist</div>
        <hr className='header-line-under-cards'/>
        <Card onClick={() => showProjectDetails('seek-app')} frontBackgroundImage={seekDesktopPhoto} imageSrc={seekPhoto} backContentTitle="Landing Page for seek by iNaturalist" backContentDescription={"A visually engaging landing page for Seek, designed to captivate users and drive conversions through clean design and compelling content."} backContentPic={seekTransparent} backContentDesc={"See More"} title="" />
        
        <div className="card-title">PixelBridge Website</div>
        <hr className='header-line-under-cards'/>
        <Card onClick={() => showProjectDetails('pixelbridge')} imageSrc={projectPhoto} frontBackgroundImage={projectPhoto} backContentTitle="PixelBridge Website" backContentDescription={"An innovative platform connecting digital artists with potential clients, featuring seamless portfolio showcasing and project management tools."} backContentPic={pixelBridgeBack} backContentDesc={"See More"} title="" />
        
        <div className="card-title">Green IQ Dispensary Website</div>
        <hr className='header-line-under-cards'/>
        <Card onClick={() => showProjectDetails('greeniq')} frontBackgroundImage={greeniq} imageSrc={greeniq} backContentTitle="Green IQ Dispensary Website" backContentDescription={"A website for the dispensary Green IQ. Consisting of a landing page, a blog, contact page, and the chance for 3rd party attachments like dutchie or leafly"} backContentPic={greeniqBlurred} backContentDesc={"See More"} title="" />
      </div>
      <div className={`project-details-container ${showDetails ? 'show' : ''}`}>
        {activeProject && (
          <ProjectDetails projectData={activeProject} onClose={hideProjectDetails} />
        )}
      </div>
    </div>
  );
};

export default Footer;
