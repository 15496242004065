import React from 'react';
import './services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const servicesData = [
    {
        title: 'Consultation Services',
        description: 'Expert guidance to understand your digital presence and identify opportunities for improvement.',
        price: '$299',
        billedAnnually: '',
        features: [
            'Initial consultation to discuss your goals and requirements',
            'Comprehensive website analysis',
            'Recommendations for design, content, and functionality improvements',
            'SEO and accessibility audit',
            '1-hour follow-up session to discuss implementation strategies',
        ],
    },
    {
        title: 'Full Website Development',
        description: 'Custom website design and development to establish a strong online presence.',
        price: '$9,999',
        billedAnnually: '',
        features: [
            'Custom website design tailored to your brand',
            'Responsive design for mobile, tablet, and desktop',
            'Up to 5 pages (Home, About, Services, Testimonials, Contact)',
            'Basic on-page SEO optimization',
            'Integration of social media links and contact forms',
            'Training on website management',
            'eCommerce integration',
            '1-month post-launch support',
            
        ],
    },
    {
        title: 'Website Optimization',
        description: 'Improve performance, enhance user experience, and update your site with the latest standards.',
        price: '$999',
        billedAnnually: '',
        features: [
            'Comprehensive website audit',
            'Speed and performance enhancements',
            'Mobile responsiveness adjustments',
            'SEO and accessibility improvements',
            'Content updates and redesigns (up to 3 pages)',
            'Integration of new features (e.g., contact forms, social media links)',
            '1-month follow-up support',
        ],
    },
];

const Services = () => {
    return (
        <div id='services' className="services-section">
            <h2>Personalized Package Examples</h2>
            <hr className='header-line'></hr>
            <p>Unlock your potential with our tailored service packages and get the specific expertise your business needs to grow and thrive.</p>
            <hr className='header-line-under' style={{ marginBottom: '50px' }} ></hr>
            <div className="services-cards">
                {servicesData.map((service, index) => (
                    <div className="service-card" key={index}>
                        <h3>{service.title}</h3>
                        <p>{service.description}</p>
                        <p className="starting-at">Starting at...</p>
                        <p className="price">{service.price}</p>
                        <p className="billed-annually">{service.billedAnnually}</p>
                        <ul>
                            {service.features.map((feature, i) => (
                                <li key={i}><FontAwesomeIcon icon={faCheck} /> {feature}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <button className="services-button" onClick={() => document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' })}>
                Connect
            </button>
        </div>
    );
};

export default Services;
