import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import './contact.css';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const Contact = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    const bottomOfPage = document.documentElement.scrollHeight - window.innerHeight;
    const currentScrollPosition = window.scrollY + window.innerHeight;
    setShowButton(currentScrollPosition >= bottomOfPage - 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id='contact' className='contact-container'>
      <h1 className="headline">Crafting Tomorrow's Solutions, Today.</h1>
      <hr className="header-line" />
      <div className="author-info">
      © 2024 MC Digital Development, LLC. All rights reserved.
      </div>
      
      <hr className='header-line-under'/>

      <div className={`back-to-top-button ${showButton ? 'show' : ''}`}>
        <Link to="top" spy={true} smooth={true} duration={500}>
          <ArrowUpwardIcon />
        </Link>
      </div>
    </div>
  );
}

export default Contact;
