import React, { useEffect, useRef, useState } from 'react';
import './welcome.css';
import { Button } from '@mui/material';
import MUIFramerMotionPopup from './Popup2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faLaptopCode, faRocket, faWrench, faSync } from '@fortawesome/free-solid-svg-icons';

const Welcome = () => {
    const textRef = useRef(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const node = textRef.current;
            node.style.animation = 'none';
            // eslint-disable-next-line no-unused-expressions
            node.offsetWidth;  
            node.style.animation = '';
        }, 8000); 

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="welcome-container">
            <h1 ref={textRef} className="welcome-name">MC Digital Development</h1>
            <p className="welcome-tagline">Elevate Your Business with Custom Websites, Enhanced Optimization, and Dynamic Web Applications.</p>
            <div className="buttons">
                {/* <Button className="welcome-button" onClick={handleOpenPopup}>About Me</Button> */}
                <Button className="welcome-button secondary-custom" onClick={handleOpenPopup}>Our Process</Button>
            </div>
            <MUIFramerMotionPopup 
                open={isPopupOpen} 
                onClose={handleClosePopup} 
                title="How We Build Your Success" 
                header=""
                description={
                    <div className="popup-description">
                        <div className="popup-section">
                            <FontAwesomeIcon icon={faComments} className="popup-icon" />
                            <div>
                                <span className="popup-section-title">Consultation:</span>
                                <p className="popup-section-description"> Understanding your needs and goals.</p>
                            </div>
                        </div>
                        <div className="popup-section">
                            <FontAwesomeIcon icon={faLaptopCode} className="popup-icon" />
                            <div>
                                <span className="popup-section-title">Design:</span>
                                <p className="popup-section-description"> Crafting a user-friendly and visually appealing design.</p>
                            </div>
                        </div>
                        <div className="popup-section">
                            <FontAwesomeIcon icon={faRocket} className="popup-icon" />
                            <div>
                                <span className="popup-section-title">Development:</span>
                                <p className="popup-section-description"> Building robust and scalable solutions.</p>
                            </div>
                        </div>
                        <div className="popup-section">
                            <FontAwesomeIcon icon={faWrench} className="popup-icon" />
                            <div>
                                <span className="popup-section-title">Optimization:</span>
                                <p className="popup-section-description"> Enhancing performance and SEO for maximum reach.</p>
                            </div>
                        </div>
                        <div className="popup-section">
                            <FontAwesomeIcon icon={faSync} className="popup-icon" />
                            <div>
                                <span className="popup-section-title">Support:</span>
                                <p className="popup-section-description">Providing ongoing maintenance and updates.</p>
                            </div>
                        </div>
                    </div>
                }
                buttonOneLabel="Our Services" 
                buttonTwoLabel="Testimonials"
            />
        </div>
    );
}

export default Welcome;
