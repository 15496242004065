import React, { useState } from 'react';
import { AppBar, Toolbar, Button, useMediaQuery, useTheme, Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-scroll';
import logo from '../../assets/portfolio-temp-logo.png';

const menuItems = [
  { text: 'Projects', to: 'projects' },
  { text: 'Testimonials', to: 'testimonials' },
  { text: 'About Me', to: 'about-me' },
  { text: 'Contact', to: 'contact-us' }
];

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawerList = () => (
    <List sx={{ width: 200, textAlign: 'center' }}>
      {menuItems.map((item, index) => (
        <Link key={index} to={item.to} spy={true} smooth={true} offset={-70} duration={500} onClick={toggleDrawer(false)}>
          <ListItem button>
            <ListItemText primary={item.text} />
          </ListItem>
        </Link>
      ))}
    </List>
  );

  return (
    <AppBar position="absolute" sx={{ backgroundColor: '#00223bf9', boxShadow: 'none', width: '100%' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src={logo} alt="Logo" style={{ maxHeight: '50px', borderRadius: '25px' }} />
        {isMobile ? (
          <>
            <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
              PaperProps={{ sx: { backgroundColor: '#222222', color: 'white', width: 'auto' } }}
            >
              {drawerList()}
            </Drawer>
          </>
        ) : (
          <div style={{ display: 'flex', gap: '20px' }}>
            {menuItems.map((item, index) => (
              <Link key={index} to={item.to} spy={true} smooth={true} offset={-70} duration={500}>
                <Button sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.08)' } }} color="inherit">{item.text}</Button>
              </Link>
            ))}
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
